import Swiper from 'swiper';
import {Navigation, Autoplay } from "swiper/modules";
Swiper.use([Navigation, Autoplay]);

const testimonialSlider  = new Swiper('.testimonial-slider',{
  direction: 'horizontal',
  slidesPerView: 1,
  spaceBetween: 30,
  autoHeight: false,
  loop: false,
  effect: 'fade',
  speed: 1000,
  fadeEffect: {
    crossFade: true
  },
  navigation:{
    nextEl: ".testimonial-swiper-button-next",
    prevEl: ".testimonial-swiper-button-prev",
  },
  breakpoints:{
    675:{
      slidesPerView: 2,
    }
  }
})
