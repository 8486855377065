window.addEventListener('load', function () {
    if (typeof gform !== 'undefined') {
        gform.addFilter('gform_file_upload_markup', function (html, file, up, strings, imagesUrl) {
            const formId = up.settings.multipart_params.form_id,
                fieldId = up.settings.multipart_params.field_id;

            html = '<strong>' + file.name + '</strong>'
            + '<a class="gform_delete button bg--primary" href="javascript:gformDeleteUploadedFile(' + formId + ', ' + fieldId + ', this);">Remove</a> ';
            return html;
        });
    }
})


