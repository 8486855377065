const $input = $('input, textarea');

$input.on('focus', function () {
  $(this).closest('.gfield').find('label').addClass('away');

  console.log($(this).attr('type'));
  console.log($(this).val().length);
});

$input.on('blur', function () {
  if (!$(this).val()) {
    $(this).closest('.gfield').find('label').removeClass('away');
  }
});

$('input[type="file"]').on('focus', function () {
  $(this).closest('.gfield').find('label').addClass('focus');
});
$('input[type="file"]').on('blur', function () {
  $(this).closest('.gfield').find('label').removeClass('focus');
});

$('footer .gfield--type-html').on('click', ()=> {
  $('.gform_footer .gform_button').click()
})

$(document).on('select2:open', 'select', function() {
  $('.select2-search--dropdown .select2-search__field').attr('placeholder', 'Type to search…');
});

$(document).on('click', '.quantity-stepper', (event) => {
  const $this = $(event.currentTarget);
  const $input = $this.parent().find('input');
  const increment = Number($this.data('increment'));

  // We have to get min and max on click bc these values change on variation select
  const min = Number($input.attr('min'));
  const max = Number($input.attr('max'));
  const step = Number($input.attr('step'));

  const initialValue = Number($input.val());

  if (max) {
    $input.val(Math.min(max, Math.max(min, initialValue + (step * increment))));
  } else {
    $input.val(Math.max(min, initialValue + (step * increment)));
  }

  $input.trigger('change');
});

//Add a container around the gravity forms button for styling purposes
$('.gform_footer').wrapInner('<div class="submit-button__container"></div>');
