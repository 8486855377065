import $ from 'jquery';

const mobileMenuBreakpoint = 1200;
const $win = $(window);
const $body = $('body');

const $header = $('.header');
const $headerMenu = $('.site-header .site-header__main');
const $headerNav = $('.site-header .site-header__main .wp-classic-menu-block');
const $subMenu = $('.site-header .menu .sub-menu');

function NavigateMobileSubMenu(level, $subMenu) {
  $subMenu = $subMenu || null;
  if($subMenu) {
    $headerMenu.find('.sub-menu[data-level=' + level + ']').removeClass('active');
    $subMenu.addClass('active');
  }
  $headerNav.attr('data-currentlevel', level);
}
$(document).on('click', '.sub-menu-back', (e)=> {
  const $this = $(e.currentTarget)
  $this.parent().parent().removeClass('active')
})

$header.find('#menu-main-menu > .menu-item-has-children').each(function () {
  const $self = $(this);
  const $link = $self.find('> a');
  const $subMenu = $self.find('> .sub-menu');
  const $subMenuBack = $subMenu.find('> .sub-menu__back');
  const linkHref = $link.attr('href');
  if (linkHref !== '#' && linkHref !== '' && linkHref !== undefined) {
    const $menuItemDuplicate = $('<li class="menu-item menu-item--duplicate"></li>');
    $link.clone().appendTo($menuItemDuplicate);
    $subMenuBack.after($menuItemDuplicate);
  }

  $link.click(function (event) {
    if ($win.outerWidth() <= mobileMenuBreakpoint) {
      event.preventDefault();
      NavigateMobileSubMenu($subMenu.data('level'), $subMenu);
    }
  });
});

$('.mobile-close, .mobile-menu-toggle').click(function () {
  $headerNav.toggleClass('open');
  $(this).toggleClass('is-active');
});

// Add header height as css var.
function setMenuHeightVar() {
  $body.css('--header-height', $header.height() + 'px');
}

// Do this on resize
$(window)
$win.on('resize load', () => {
  setMenuHeightVar();
});
